// src/components/Dashboard/TickerPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function TickerPage() {
  const { symbol } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchTickerData = async () => {
      try {
        // Replace with your backend endpoint to get ticker details
        const response = await axios.get(`/api/ticker/${symbol}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });
        setData(response.data);
      } catch (error) {
        console.error('Failed to fetch ticker data', error);
        alert('Failed to load ticker data.');
      }
    };

    fetchTickerData();
  }, [symbol]);

  if (!data) return <p className="text-center">Loading...</p>;

  const handleDownload = async (type) => {
    try {
      // Replace with your backend endpoint to download summaries
      const response = await axios.get(`/api/ticker/${symbol}/download/${type}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${symbol}_${type}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Download failed', error);
      alert('Failed to download the document.');
    }
  };

  return (
    <div className="max-w-3xl mx-auto bg-white shadow-md rounded px-8 py-6 mt-6">
      <h2 className="text-3xl mb-4 font-semibold">{data.companyName} ({symbol})</h2>
      <p className="mb-4 text-gray-700">{data.description}</p>
      <p className="mb-4"><span className="font-semibold">Value per Share:</span> ${data.valuePerShare}</p>
      <div className="flex space-x-4">
        <button 
          onClick={() => handleDownload('10-Q')} 
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-200"
        >
          Download 10-Q Summary
        </button>
        <button 
          onClick={() => handleDownload('10-K')} 
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-200"
        >
          Download 10-K Summary
        </button>
      </div>
    </div>
  );
}

export default TickerPage;
