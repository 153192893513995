// src/components/Dashboard/SearchBar.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SearchBar() {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = async e => {
    e.preventDefault();
    try {
      // Replace with your backend search endpoint
      const response = await axios.get(`/api/search?query=${encodeURIComponent(query)}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      // Assuming response.data contains the ticker symbol
      if(response.data.symbol){
        navigate(`/ticker/${response.data.symbol}`);
      } else {
        alert('Ticker not found');
      }
    } catch (error) {
      console.error('Search failed', error);
      alert('Search failed. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSearch} className="mb-6 flex">
      <input 
        type="text" 
        placeholder="Search for a ticker..." 
        value={query} 
        onChange={e => setQuery(e.target.value)} 
        required 
        className="flex-grow px-4 py-2 border border-gray-300 rounded-l focus:outline-none focus:ring focus:border-blue-300"
      />
      <button 
        type="submit" 
        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-r transition duration-200"
      >
        Search
      </button>
    </form>
  );
}

export default SearchBar;
