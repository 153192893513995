// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';
import './styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Initialize AOS
AOS.init({
  duration: 800, // Animation duration in milliseconds
  once: true,     // Whether animation should happen only once
});

// Set the base URL for Axios
axios.defaults.baseURL = 'https://www.lseer.com/api'; // Replace with your backend URL

// Add a request interceptor to include the token
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if(token){
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

ReactDOM.render(<App />, document.getElementById('root'));
