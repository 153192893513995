// src/components/Dashboard/TickerTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function TickerTable() {
  const [tickers, setTickers] = useState([]);
  const [filters, setFilters] = useState({ industry: '', capitalization: '' });
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  useEffect(() => {
    const fetchTickers = async () => {
      try {
        // Replace with your backend endpoint to get tickers
        const response = await axios.get('/api/tickers', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          params: filters
        });
        setTickers(response.data);
      } catch (error) {
        console.error('Failed to fetch tickers', error);
        alert('Failed to load tickers.');
      }
    };

    fetchTickers();
  }, [filters]);

  const handleSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    sortArray(key, direction);
  };

  const sortArray = (key, direction) => {
    const sorted = [...tickers].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setTickers(sorted);
  };

  const handleFilterChange = e => {
    setFilters({...filters, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <h3 className="text-2xl mb-4 font-semibold">Ticker List</h3>
      <div className="mb-6 flex flex-wrap gap-4">
        <select 
          name="industry" 
          value={filters.industry} 
          onChange={handleFilterChange} 
          className="px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
        >
          <option value="">All Industries</option>
          {/* Populate with dynamic industries if possible */}
          <option value="Technology">Technology</option>
          <option value="Finance">Finance</option>
          {/* Add more industries as needed */}
        </select>
        <select 
          name="capitalization" 
          value={filters.capitalization} 
          onChange={handleFilterChange} 
          className="px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
        >
          <option value="">All Capitalizations</option>
          <option value="Large">Large</option>
          <option value="Mid">Mid</option>
          <option value="Small">Small</option>
        </select>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th 
                className="px-6 py-3 border-b cursor-pointer" 
                onClick={() => handleSort('valuePerShare')}
              >
                Value per Share
              </th>
              <th 
                className="px-6 py-3 border-b cursor-pointer" 
                onClick={() => handleSort('stockPrice')}
              >
                Stock Price
              </th>
              <th 
                className="px-6 py-3 border-b cursor-pointer" 
                onClick={() => handleSort('valueMinusPrice')}
              >
                Value - Price
              </th>
              <th 
                className="px-6 py-3 border-b cursor-pointer" 
                onClick={() => handleSort('revenue')}
              >
                Revenue
              </th>
              <th 
                className="px-6 py-3 border-b cursor-pointer" 
                onClick={() => handleSort('industry')}
              >
                Industry
              </th>
              <th 
                className="px-6 py-3 border-b cursor-pointer" 
                onClick={() => handleSort('capitalization')}
              >
                Capitalization
              </th>
            </tr>
          </thead>
          <tbody>
            {tickers.map(ticker => (
              <tr key={ticker.symbol} className="hover:bg-gray-100">
                <td className="px-6 py-4 border-b">
                  <Link to={`/ticker/${ticker.symbol}`} className="text-blue-500 hover:underline">
                    {ticker.valuePerShare}
                  </Link>
                </td>
                <td className="px-6 py-4 border-b">{ticker.stockPrice}</td>
                <td className="px-6 py-4 border-b">{ticker.valueMinusPrice}</td>
                <td className="px-6 py-4 border-b">{ticker.revenue}</td>
                <td className="px-6 py-4 border-b">{ticker.industry}</td>
                <td className="px-6 py-4 border-b">{ticker.capitalization}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TickerTable;
