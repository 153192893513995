// src/components/Dashboard/Dashboard.js
import React from 'react';
import SearchBar from './SearchBar';
import TickerTable from './TickerTable';

function Dashboard() {
  return (
    <div>
      <h2 className="text-3xl mb-6 text-center font-semibold">Dashboard</h2>
      <SearchBar />
      <TickerTable />
    </div>
  );
}

export default Dashboard;
