// src/components/Public/LandingPage.js
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ChartBarIcon,
  DocumentTextIcon,
  ArrowPathIcon, // Updated Icon for Heroicons v2
  CurrencyDollarIcon,
  CogIcon,
  CubeIcon,        // Icon for Embeddings
} from '@heroicons/react/24/outline'; // Updated import path for Heroicons v2
import AOS from 'aos';
import 'aos/dist/aos.css';

function LandingPage() {
  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration in milliseconds
      once: true,     // Whether animation should happen only once
    });
    AOS.refresh();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Hero Section */}
      <section className="flex-grow flex items-center justify-center bg-gradient-to-r from-blue-500 to-indigo-600">
        <div className="text-center text-white px-4 py-12" data-aos="fade-up">
          <h1 className="text-5xl md:text-6xl font-bold mb-4">Luminar Seer</h1>
          <p className="text-xl md:text-2xl mb-12">
            Empowering your investment decisions with cutting-edge tools and insights.
          </p>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6">
            <Link
              to="/signup"
              className="bg-white text-blue-600 font-semibold px-6 py-3 rounded shadow hover:bg-gray-100 transition duration-200"
            >
              Get Started
            </Link>
            {/* Removed Login button from here */}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-white text-gray-800 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center mb-8" data-aos="fade-up">Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Feature 1: Valuations */}
            <div
              className="text-center p-6 bg-gray-100 rounded-lg shadow transform transition duration-500 hover:scale-105"
              data-aos="zoom-in"
            >
              <div className="mb-4">
                <ChartBarIcon className="w-12 h-12 mx-auto text-blue-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Valuations</h3>
              <p className="text-gray-600">
                Receive a single value per share, expertly calculated using our DCF-based and AI-empowered approach. No complex math required — just a clear dollar value for informed decisions.
              </p>
            </div>

            {/* Feature 2: Summaries */}
            <div
              className="text-center p-6 bg-gray-100 rounded-lg shadow transform transition duration-500 hover:scale-105"
              data-aos="zoom-in"
            >
              <div className="mb-4">
                <DocumentTextIcon className="w-12 h-12 mx-auto text-blue-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Summaries</h3>
              <p className="text-gray-600">
                Access easy-to-read 10-Q and 10-K summaries to stay informed without the jargon.
              </p>
            </div>

            {/* Feature 3: Updates */}
            <div
              className="text-center p-6 bg-gray-100 rounded-lg shadow transform transition duration-500 hover:scale-105"
              data-aos="zoom-in"
            >
              <div className="mb-4">
                <ArrowPathIcon className="w-12 h-12 mx-auto text-blue-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Updates</h3>
              <p className="text-gray-600">
                Discover what's new in the latest 10-Q/K reports compared to previous filings.
              </p>
            </div>

            {/* Feature 4: Financials */}
            <div
              className="text-center p-6 bg-gray-100 rounded-lg shadow transform transition duration-500 hover:scale-105"
              data-aos="zoom-in"
            >
              <div className="mb-4">
                <CurrencyDollarIcon className="w-12 h-12 mx-auto text-blue-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Financials</h3>
              <p className="text-gray-600">
                Dive deep into comprehensive financial data to make informed investment choices.
              </p>
            </div>

            {/* Feature 5: API */}
            <div
              className="text-center p-6 bg-gray-100 rounded-lg shadow transform transition duration-500 hover:scale-105"
              data-aos="zoom-in"
            >
              <div className="mb-4">
                <CogIcon className="w-12 h-12 mx-auto text-blue-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">API</h3>
              <p className="text-gray-600">
                Integrate seamlessly with our API for automated trading and streamlined workflows.
              </p>
            </div>

            {/* Feature 6: Embeddings */}
            <div
              className="text-center p-6 bg-gray-100 rounded-lg shadow transform transition duration-500 hover:scale-105"
              data-aos="zoom-in"
            >
              <div className="mb-4">
                <CubeIcon className="w-12 h-12 mx-auto text-blue-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Embeddings</h3>
              <p className="text-gray-600">
                Utilize advanced company embeddings to uncover hidden relationships and similarities, enhancing your analysis and investment strategies.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="bg-gray-100 text-gray-800 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center mb-8" data-aos="fade-up">What Our Users Say</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Testimonial 1 */}
            <div
              className="bg-white p-6 rounded-lg shadow"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <p className="text-gray-700 mb-4">
                "Luminar Seer has transformed my investment strategy with its insightful valuations and easy-to-read summaries."
              </p>
              <p className="text-gray-900 font-semibold">- Joseph Clark</p>
            </div>
            {/* Testimonial 2 */}
            <div
              className="bg-white p-6 rounded-lg shadow"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <p className="text-gray-700 mb-4">
                "The API access has streamlined my automated trading, making my workflow incredibly efficient."
              </p>
              <p className="text-gray-900 font-semibold">- Michael Lee</p>
            </div>
            {/* Testimonial 3 */}
            <div
              className="bg-white p-6 rounded-lg shadow"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <p className="text-gray-700 mb-4">
                "I love how I can compare the latest financial reports effortlessly. It saves me so much time!"
              </p>
              <p className="text-gray-900 font-semibold">- Sophia Martinez</p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="bg-indigo-600 text-white py-12">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-semibold mb-4" data-aos="fade-up">Ready to Elevate Your Investments?</h2>
          <p className="text-lg mb-6" data-aos="fade-up" data-aos-delay="100">
            Sign up today to start exploring and managing your favorite US publicly traded companies with advanced tools.
          </p>
          <div className="flex justify-center space-x-4" data-aos="fade-up" data-aos-delay="200">
            <Link
              to="/signup"
              className="bg-white text-indigo-600 font-semibold px-6 py-3 rounded shadow hover:bg-gray-100 transition duration-200"
            >
              Get Started
            </Link>
            {/* Removed Login button from here */}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-gray-400 py-6">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} Luminar Seer by <a href="https://www.palmira.ai">Palmira Inc.</a> All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
